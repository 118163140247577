<template>
  <div>
    <el-upload
      ref="uploadForm"
      drag
      multiple
      action=""
      show-file-list
      accept=".rar, .zip, .pdf, .doc, .docx, .xls, .xlsx"
      :file-list="fileList"
      :http-request="handleUploadFile"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      :disabled="isDisabled"
      :on-change="fileChange"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div slot="tip" class="el-upload__tip">只支持单个或批量上传，支持格式：.rar .zip .doc .docx .pdf .xls .xlsx单个文件最大不超过30M</div>
      <div slot="file" slot-scope="{file}">
        <el-button type="text" :loading="uploadLoading" @click="downloadReplenish(file)">
          <el-popover
            placement="top-start"
            trigger="hover"
          >
            <div>{{ file.name }}</div>
            <div slot="reference" style="width:300px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;text-align:start">{{ file.name }}</div>
          </el-popover>
        </el-button>
        <el-button v-if="!isDisabled" style="color: red" type="text" :loading="uploadLoading" @click="handleRemove(file)">删除</el-button>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { uploadByPreUrlApi, getFileDownloadUrlByFileIds } from '@/api/scm-api'
import axios from 'axios'
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    attachmentUrls: {
      type: Array,
      default: () => []
    },
    attachmentIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
      fileKeyList: [],
      oldFileList: [],
      uploadLoading: false
    }
  },
  watch: {
    'attachmentUrls'(val) {
      if (val) {
        const getUrls = this.attachmentUrls
        for (let i = 0; i < getUrls.length; i++) {
          this.fileList.push({ uid: this.attachmentIdList[i].id, url: getUrls[i], name: this.attachmentIdList[i].fileName, id: this.attachmentIdList[i].id })
        }
        this.oldFileList = this.fileList
        this.$emit('getFile', { fileList: this.fileList, newFileList: this.fileKeyList, oldFileList: this.oldFileList })
      }
    }
  },
  mounted() {
  },
  methods: {
    async downloadReplenish(file) {
      try {
        this.uploadLoading = true
        const { id, downloadUrl } = file
        downloadUrl && window.open(downloadUrl, '_blank')
        const { datas } = await getFileDownloadUrlByFileIds([id])
        datas.map(item => window.open(item, '_blank'))
        this.uploadLoading = false
      } finally {
        this.uploadLoading = false
      }
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['rar', 'zip', 'pdf', 'doc', 'docx', 'xls', 'xlsx']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 rar、zip、pdf、doc、docx、xls、xlsx格式')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 30
      if (!isLt2M) {
        this.$message.error('文件最大不超过30MB')
        return false
      } else {
        return true
      }
    },
    async handleUploadFile(options) {
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName: new Date().getTime() + fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })
      response && options.onSuccess(response) // 成功之后取值
    },
    handleSuccess(response, file, fileList) {
      Object.assign(file, response)
    },
    handleRemove(val) {
      if (val.status === 'ready') return
      const index = this.fileList.findIndex((item) => item.uid === val.uid)
      this.fileList.splice(index, 1)
      this.fileChange({}, this.fileList)
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.fileKeyList = this.fileList.filter(item => item.objectName).map(i => i.objectName)
      this.oldFileList = this.fileList.filter(item => item.id)
      this.$emit('getFile', { fileList, newFileList: this.fileKeyList, oldFileList: this.oldFileList })
    }
  }

}
</script>
