<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">基本信息</el-col>
    </el-row>

    <el-form ref="basicForm" :model="basicForm" :rules="basicRules" label-width="120px">
      <el-row :span="24">
        <el-col :span="24">
          <el-form-item v-if="type==='edit'" label="修改类型" prop="modifyType">
            <el-radio v-model="basicForm.modifyType" label="1">材质 / 工艺 / 模具 / Logo更新</el-radio>
            <el-radio v-model="basicForm.modifyType" label="0">包装规格书 / 印刷更新</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="包装代码" prop="packagingCode">
            <el-input v-model="basicForm.packagingCode" type="text" :placeholder="$t('page.inputPlaceholder')" maxlength="20" show-word-limit clearable :disabled="type!=='add'" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌" prop="brandId">
            <el-select v-model="basicForm.brandId" class="style_w100" :placeholder="$t('page.selectPlaceholder')" clearable :disabled="type!=='add'">
              <el-option
                v-for="item in brandOptions"
                :key="item.id"
                :label="item.brandName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="包装类型" prop="typeDict">
            <el-select v-model="basicForm.typeDict" class="style_w100" :placeholder="$t('page.selectPlaceholder')" clearable :disabled="type!=='add'">
              <el-option
                v-for="item in _getAllCommodityDict('SCM_PACKAGING_TYPE')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="材料/款式说明" prop="remark">
            <el-input
              v-model="basicForm.remark"
              type="textarea"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="300"
              show-word-limit
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主要包装辅材" prop="mainInfo">
            <el-input
              v-model="basicForm.mainInfo"
              type="textarea"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="300"
              show-word-limit
              :disabled="type==='view'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">包装信息</el-col>
    </el-row>
    <el-form ref="packagingForm" :model="packagingForm" :rules="packagingRules" label-width="120px">
      <el-row :span="24">
        <el-col :span="24">
          <el-form-item label="文件上传" prop="file">
            <Upload v-model="packagingForm.file" :is-disabled="type==='view'" :attachment-urls="attachmentUrls" :attachment-id-list="attachmentIdList" @getFile="getFile" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="版本说明" prop="wangHtml">
            <Editor
              v-model="packagingForm.wangHtml"
              :type="type"
              :version-html="versionHtml"
              @getHtml="getHtml"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top:50px">
      <el-button @click="goBack()">返回</el-button>
      <el-button v-if="type!=='view'" :loading="submitLoading" type="primary" @click="toSubmit()">提交</el-button>
    </el-row>

  </div>
</template>
<script>
import { queryBrandList } from '@/api/listSelection'
import { getFileDownloadUrlByFileIds } from '@/api/scm-api'
import { insertPackagingCode, updatePackagingCode, PackagingCodeInfo } from '@/api/package'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Editor from '@/components/ImagesEditor'
import Upload from '../components/Upload.vue'

export default {
  components: { Editor, Upload },
  mixins: [commodityInfoDict],
  data() {
    return {
      type: '',
      brandOptions: [],
      basicForm: {
        modifyType: '',
        packagingCode: '',
        brandId: '',
        typeDict: '',
        remark: '',
        mainInfo: ''
      },
      basicRules: {
        modifyType: { required: true, message: this.$t('page.required'), trigger: 'change' },
        packagingCode: { required: true, message: this.$t('page.required'), trigger: 'change' },
        brandId: { required: true, message: this.$t('page.required'), trigger: 'change' },
        typeDict: { required: true, message: this.$t('page.required'), trigger: 'change' },
        remark: { required: true, message: this.$t('page.required'), trigger: 'change' },
        mainInfo: { required: true, message: this.$t('page.required'), trigger: 'change' }
      },
      packagingForm: {
        file: '',
        wangHtml: ''
      },
      packagingRules: {
        file: { required: true, message: this.$t('page.required'), trigger: 'change' },
        wangHtml: { required: true, message: this.$t('page.required'), trigger: 'change' }
      },
      submitLoading: false,
      attachmentUrls: [],
      attachmentIdList: [],
      versionHtml: '',
      oldFileList: []
    }
  },
  computed: {
  },
  watch: {

  },
  mounted() {
    this.type = this.$route.query.type
    this._brandList()
    if (this.type !== 'add') {
      this.getDetail()
    }
  },
  methods: {
    getHtml(val) {
      if (!val) return
      this.packagingForm.wangHtml = val
    },
    getFile(val) {
      if (!val) return
      const { newFileList, oldFileList, fileList } = val
      this.packagingForm.file = fileList
      this.newFileList = newFileList
      this.oldFileList = oldFileList
    },
    async getDetail() {
      const { datas } = await PackagingCodeInfo(this.$route.query.id)
      this.basicForm = datas
      const { attachmentIdList, versionHtml, attachmentVos, brandId } = datas
      this.basicForm.brandId = Number(brandId)
      this.versionHtml = versionHtml
      this.attachmentIdList = attachmentVos
      const arr = JSON.parse(attachmentIdList)
      const { datas: attachmentUrls } = await getFileDownloadUrlByFileIds(arr)
      this.attachmentUrls = attachmentUrls
    },
    toSubmit() {
      this.$refs['basicForm'].validate((valid) => {
        if (valid) {
          this.$refs['packagingForm'].validate(async(valid) => {
            if (valid) {
              const { wangHtml } = this.packagingForm
              const { modifyType, packagingCode, brandId, typeDict, remark, mainInfo } = this.basicForm
              const params = {}
              const id = this.$route.query.id || ''
              const version = this.$route.query.version || ''
              const attachmentIds = this.oldFileList?.map(item => item.id) || []
              const brandName = this.brandOptions.find(item => item.id === brandId).brandName
              Object.assign(params, { modifyType, packagingCode, brandId: Number(brandId), brandName, typeDict, remark, mainInfo, id: Number(id), version }, { attachmentFileKeys: this.newFileList || [], versionHtml: wangHtml, attachmentIds })
              try {
                this.submitLoading = true
                if (this.type === 'add') {
                  await insertPackagingCode(params)
                  this.goBack()
                } else {
                  await updatePackagingCode(params)
                  this.goBack()
                }
              } finally {
                this.submitLoading = false
              }
            }
          })
        }
      })
    },
    async _brandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scope>

.style_w100 {
  width: 100%;
}

</style>
