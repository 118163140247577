<template>
  <div style="border: 1px solid #ccc; margin-top: 10px">
    <!-- 工具栏 -->
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :default-config="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      v-model="newHtml"
      :style="{ height: height+'px', overflowY: 'hidden' }"
      :default-config="editorConfig"
      @onCreated="onCreated"
      @onChange="onChanged"
    />
  </div>
</template>
<script>
import { uploadByPreUrlApi } from '@/api/scm-api'
import axios from 'axios'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'EditorVue',
  components: { Editor, Toolbar },
  props: {
    versionHtml: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      editor: null,
      newHtml: '',
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          'headerSelect',
          // '|',
          'bold',
          'underline',
          'italic',
          'color',
          'bgColor',
          // '|',
          'indent', // 增加缩进
          'delIndent', // 减少缩进
          'justifyLeft', // 左对齐
          'justifyRight', // 右对齐
          'justifyCenter', // 居中
          'justifyJustify', // 两端对齐
          // '|',
          'fontSize',
          'fontFamily',
          'lineHeight',
          // '|',
          'bulletedList',
          'numberedList',
          'todo',
          'insertLink',
          'insertTable',
          // '|',
          'codeBlock',
          'divider',
          'uploadImage',
          'undo',
          'redo'
        ]
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: '请输入内容',
        // autoFocus: false,
        // readOnly: true, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            // 自定义图片上传
            customUpload: this.uploaadImg
          }
        }
      }

    }
  },
  watch: {
    versionHtml: {
      handler: function(val, oldVal) {
        this.newHtml = val
      },
      immediate: true
    }
    // versionHtml(val) {
    //   if (val) {
    //     this.newHtml = this.versionHtml
    //   }
    // }
  },
  mounted() {
    setTimeout(() => {
      if (this.type === 'view') {
        this.editor.disable()
      }
    }, 30)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor
  },
  methods: {
    async uploaadImg(file, insertFn) {
      const fileFormImg = {}
      const { name: fileName, size: kbSize } = file
      Object.assign(fileFormImg, {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      })
      const { datas } = await uploadByPreUrlApi(fileFormImg)

      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        success_action_status = 200,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, success_action_status, callback, signature },
        { file }
      )

      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set([key], form[key]))
      const { data } = await axios({
        method: 'post',
        url,
        data: formData
      })
      if (data.code === 0) {
        // 插入后端返回的url
        const newurl = data.datas.downloadUrl
        insertFn(newurl)
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor)
    },
    onChanged(editor) {
      const wangHtml = editor.getHtml()
      this.$emit('getHtml', wangHtml)
    }

  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
